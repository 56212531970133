import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../Components/Header";
import Entrepreneurs from "./Entrepreneurs";
import Licensed from "./Licensed";
import Influencer from "./Influencer";
import Teachers from "./Teachers";
import Artists from "./Artists";
import Models from "./Models";
import Athlete from "./Athlete";
import Main from "./Main";
import Footer from "../Components/Footer";
import Sleazy from "./Sleazy";
import { useLokaliser } from "lokaliser";
import { useNavigate } from "react-router-dom";
import Host from "../Pages/Host";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import HostRedirect from "./HostRedirect";


function RoutesList() {

  const { language } = useLokaliser();
  const navigate = useNavigate();

  useEffect(() => {

    let currentUrl = window.location.href;
    let pathname = window.location.pathname;

    if (pathname == '/') {
      return navigate(`/${language}`);
    }

    /*
        if (urlLang !== 'en' && urlLang !== 'es' && urlLang !== 'pt') {
          return window.location.pathname = `/${language}/${urlLang}`;
        }
    
        if (urlLang.length === 0) return navigate(`/${language}`);
    */
  }, []);

  const queryClient = new QueryClient()

  const [hostData, setHostData] = useState({ slug: null, firstName: null });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Header hostData={hostData} />
        <Routes>
          <Route path="/pt" element={<Main />} />
          <Route path="/en" element={<Main />} />
          <Route path="/es" element={<Main />} />
          <Route path="/:lang/athlete" element={<Athlete />} />
          <Route path="/:lang/models" element={<Models />} />
          <Route path="/:lang/artists" element={<Artists />} />
          <Route path="/:lang/teachers" element={<Teachers />} />
          <Route path="/:lang/influencer" element={<Influencer />} />
          <Route path="/:lang/licensed" element={<Licensed />} />
          <Route path="/:lang/entrepreneurs" element={<Entrepreneurs />} />
          <Route path="/:lang/sassy" element={<Sleazy />} />
          <Route path="/:slug" element={<Host setHostData={setHostData} />} />
          <Route path="/:lang/:slug" element={<HostRedirect />} />
        </Routes>
        <Footer />
      </QueryClientProvider>
    </>
  );
}

export default RoutesList;
