import React from 'react'
import hero from '../../Images/Ingredient/hero.png'
import heroDesk from '../../Images/Ingredient/heroDesk.png'
import logo from '../../Images/Ingredient/logo.svg'
import { useLokaliser } from 'lokaliser';
import { Link } from 'react-router-dom';

function Ingredient() {
    const { languageKeys } = useLokaliser()

    return (
        <div className='h-[720px] md:h-[1000px] py-4 bg-[#CEA7FF]'>
            <div className='bg-ingredientBg md:bg-ingredientBgDesk bg-no-repeat bg-[length:90%]  bg-center h-full flex items-center justify-center'>
                <div className='bg-[#1E1E1ECC] w-[80%] md:max-w-[540px]  md:w-full md:m-auto rounded-[40px] p-10'>
                    <img className='w-[100px] md:w-[170px] m-auto md:my-10' src={logo} alt="logo" />
                    <p className='text-center text-2xl md:text-[37px] font-normal tracking-[-0.5px] mt-5 text-white md:pb-4'>{languageKeys?.mainIngredientBlockTitle}</p>
                    <Link className='w-full' to='https://app.staging.morgis.com/auth/signup-host' target='_blank'>
                        <button className='bg-[#8649D1] w-full md:max-w-[380px] m-auto text-white text-base md:text-2xl h-[50px] md:h-[70px] flex items-center justify-center rounded-[40px] mt-3 tracking-[1px] font-bold'>{languageKeys?.mainIngredientBlockBtn}</button>
                    </Link>
                    <Link className='w-full' to='https://app.staging.morgis.com/auth/login-host' target='_blank'>
                        <p className='text-sm md:text-lg font-normal tracking-[-0.4px] text-center mt-2 text-white'>{languageKeys?.mainIngredientBlockLink}
                            <span className='text-white border-b border-white pb-[1px] cursor-pointer font-medium ml-2'>{languageKeys?.mainIngredientBlockLinkSpan}</span>
                        </p>
                    </Link>
                </div>
            </div>
        </div>

    )
}

export default Ingredient
