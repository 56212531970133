

import React, { useState } from 'react'
import Create from './Main/Create'
import Faq from '../Components/Faq'
import Home from './Main/Home'
import Host from './Main/Host'
import Ingredient from './Main/Ingredient'
import SliderBg from './Main/SliderBg'
import AdvantagesList from './Main/AdvantagesList'
import slide1 from '../Images/Paid/Licensed/slide1.gif';
import slide2 from '../Images/Paid/Licensed/slide2.gif';
import slide3 from '../Images/Paid/Licensed/slide3.gif';
import slide4 from '../Images/Paid/Licensed/slide4.gif';
import slideBg1 from '../Images/bg/Licensed/bg1.png';
import slideBg2 from '../Images/bg/Licensed/bg2.png';
import slideBg3 from '../Images/bg/Licensed/bg3.png';
import slideBg4 from '../Images/bg/Licensed/bg4.png';
import slideBgDesk1 from '../Images/bg/Licensed/bg1Desk.png';
import slideBgDesk2 from '../Images/bg/Licensed/bg2Desk.png';
import slideBgDesk3 from '../Images/bg/Licensed/bg3Desk.png';
import slideBgDesk4 from '../Images/bg/Licensed/bg4Desk.png';
import { useLokaliser } from 'lokaliser';
import sliderBg1 from '../Images/slideBg/influencer1.png';
import sliderBg1Desk from '../Images/slideBg/influencer1Desk.png';
import { Helmet } from 'react-helmet'

function Licensed() {
    const { languageKeys } = useLokaliser();
    const [activeSlide, setActiveSlide] = useState(0);

    const slides = [
        { id: 0, img: slide1, title: languageKeys?.licensedCardName1, subtitle: languageKeys?.licensedCardRole1, workText: languageKeys?.licensedCardBold1, paidText: languageKeys?.licensedCardLight1, underSlideTitle: languageKeys?.licensedCardSpan1, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageKeys?.licensedCardName2, subtitle: languageKeys?.licensedCardRole2, workText: languageKeys?.licensedCardBold2, paidText: languageKeys?.licensedCardLight2, underSlideTitle: languageKeys?.licensedCardSpan2, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageKeys?.licensedCardName3, subtitle: languageKeys?.licensedCardRole3, workText: languageKeys?.licensedCardBold3, paidText: languageKeys?.licensedCardLight3, underSlideTitle: languageKeys?.licensedCardSpan3, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageKeys?.licensedCardName4, subtitle: languageKeys?.licensedCardRole4, workText: languageKeys?.licensedCardBold4, paidText: languageKeys?.licensedCardLight4, underSlideTitle: languageKeys?.licensedCardSpan4, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
        { id: 0, img: slide1, title: languageKeys?.licensedCardName1, subtitle: languageKeys?.licensedCardRole1, workText: languageKeys?.licensedCardBold1, paidText: languageKeys?.licensedCardLight1, underSlideTitle: languageKeys?.licensedCardSpan1, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageKeys?.licensedCardName2, subtitle: languageKeys?.licensedCardRole2, workText: languageKeys?.licensedCardBold2, paidText: languageKeys?.licensedCardLight2, underSlideTitle: languageKeys?.licensedCardSpan2, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageKeys?.licensedCardName3, subtitle: languageKeys?.licensedCardRole3, workText: languageKeys?.licensedCardBold3, paidText: languageKeys?.licensedCardLight3, underSlideTitle: languageKeys?.licensedCardSpan3, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageKeys?.licensedCardName4, subtitle: languageKeys?.licensedCardRole4, workText: languageKeys?.licensedCardBold4, paidText: languageKeys?.licensedCardLight4, underSlideTitle: languageKeys?.licensedCardSpan4, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
    ];


   
    const sliderBg = [
        {
            imageUrl: sliderBg1,
            imageUrlDesk: sliderBg1Desk
        }
    ];
    return (
        <div>
            <Helmet>
                <title>Licensed Professionals - Creators Morgis</title>
            </Helmet>
            <div className='mt-[50px]'>
                <Home slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
                <Create />
                <Host />
                <AdvantagesList />
                <SliderBg sliderBg={sliderBg} quoteTitle={languageKeys?.licensedQuoteDesc} quoteName={languageKeys?.licensedQuoteName} quoterole={languageKeys?.licensedQuoteRole} />
                <Ingredient />
                <Faq />
            </div>
        </div>
    )
}

export default Licensed
