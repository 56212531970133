import { AnimatePresence, motion, Variants } from "framer-motion";
import React, { useEffect, useState } from "react";
import { cn } from "../utills/utills";

interface SlightFlipProps {
  word: string;
  duration?: number;
  delayMultiple?: number;
  framerProps?: Variants;
  className?: string;
}

export default function FlipText({
  word,
  duration = 0.5,
  delayMultiple = 0,
  framerProps = {
    hidden: { rotateX: -90, opacity: 0 },
    visible: { rotateX: 0, opacity: 1 },
  },
  className,
}: SlightFlipProps) {
  const [charKeys, setCharKeys] = useState<string[]>([]);

  useEffect(() => {
    // Update the unique keys whenever the word changes
    const newKeys = word.split("").map((char, index) => `${char}-${index}-${Math.random()}`);
    setCharKeys(newKeys);
  }, [word]);

  return (
    <div className="flex space-x-2">
      <AnimatePresence mode="wait">
        {charKeys.map((key, i) => (
          <motion.span
            key={key}
            initial="hidden"
            animate="visible"
            exit="visible"
            variants={framerProps}
            transition={{ duration, delay: i * delayMultiple }}
            className={cn("origin-center drop-shadow-sm", className)}
          >
            {word[i]}
          </motion.span>
        ))}
      </AnimatePresence>
    </div>
  );
}
