import React, { useState } from 'react'
import Create from './Main/Create'
import Faq from '../Components/Faq'
import Home from './Main/Home'
import Host from './Main/Host'
import Ingredient from './Main/Ingredient'
import SliderBg from './Main/SliderBg'
import AdvantagesList from './Main/AdvantagesList'
import slide1 from '../Images/Paid/Entrepreneurs/slide1.gif';
import slide2 from '../Images/Paid/Entrepreneurs/slide2.gif';
import slide3 from '../Images/Paid/Entrepreneurs/slide3.gif';
import slide4 from '../Images/Paid/Entrepreneurs/slide4.gif';
import slideBg1 from '../Images/bg/Entrepreneurs/bg1.png';
import slideBg2 from '../Images/bg/Entrepreneurs/bg2.png';
import slideBg3 from '../Images/bg/Entrepreneurs/bg3.png';
import slideBg4 from '../Images/bg/Entrepreneurs/bg4.png';
import slideBgDesk1 from '../Images/bg/Entrepreneurs/bg1Desk.png';
import slideBgDesk2 from '../Images/bg/Entrepreneurs/bg2Desk.png';
import slideBgDesk3 from '../Images/bg/Entrepreneurs/bg3Desk.png';
import slideBgDesk4 from '../Images/bg/Entrepreneurs/bg4Desk.png';
import { useLokaliser } from 'lokaliser';
import sliderBg1 from '../Images/slideBg/entrepreneurs1.png';
import sliderBg1Desk from '../Images/slideBg/entrepreneurs1Desk.png';
import { Helmet } from 'react-helmet'

function Entrepreneurs() {
    const { languageKeys } = useLokaliser();
    const [activeSlide, setActiveSlide] = useState(0);

    const slides = [
        { id: 0, img: slide1, title: languageKeys?.entrepreneursCardName1, subtitle: languageKeys?.entrepreneursCardRole1, workText: languageKeys?.entrepreneursCardBold1, paidText: languageKeys?.entrepreneursCardLight1, underSlideTitle: languageKeys?.entrepreneursCardSpan1, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageKeys?.entrepreneursCardName2, subtitle: languageKeys?.entrepreneursCardRole2, workText: languageKeys?.entrepreneursCardBold2, paidText: languageKeys?.entrepreneursCardLight2, underSlideTitle: languageKeys?.entrepreneursCardSpan2, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageKeys?.entrepreneursCardName3, subtitle: languageKeys?.entrepreneursCardRole3, workText: languageKeys?.entrepreneursCardBold3, paidText: languageKeys?.entrepreneursCardLight3, underSlideTitle: languageKeys?.entrepreneursCardSpan3, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageKeys?.entrepreneursCardName4, subtitle: languageKeys?.entrepreneursCardRole4, workText: languageKeys?.entrepreneursCardBold4, paidText: languageKeys?.entrepreneursCardLight4, underSlideTitle: languageKeys?.entrepreneursCardSpan4, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
        { id: 0, img: slide1, title: languageKeys?.entrepreneursCardName1, subtitle: languageKeys?.entrepreneursCardRole1, workText: languageKeys?.entrepreneursCardBold1, paidText: languageKeys?.entrepreneursCardLight1, underSlideTitle: languageKeys?.entrepreneursCardSpan1, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageKeys?.entrepreneursCardName2, subtitle: languageKeys?.entrepreneursCardRole2, workText: languageKeys?.entrepreneursCardBold2, paidText: languageKeys?.entrepreneursCardLight2, underSlideTitle: languageKeys?.entrepreneursCardSpan2, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageKeys?.entrepreneursCardName3, subtitle: languageKeys?.entrepreneursCardRole3, workText: languageKeys?.entrepreneursCardBold3, paidText: languageKeys?.entrepreneursCardLight3, underSlideTitle: languageKeys?.entrepreneursCardSpan3, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageKeys?.entrepreneursCardName4, subtitle: languageKeys?.entrepreneursCardRole4, workText: languageKeys?.entrepreneursCardBold4, paidText: languageKeys?.entrepreneursCardLight4, underSlideTitle: languageKeys?.entrepreneursCardSpan4, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
    ];

    
    const sliderBg = [
        {
            imageUrl: sliderBg1,
            imageUrlDesk: sliderBg1Desk
        }
    ];
    return (
        <div>
            <Helmet>
                <title>Entrepreneurs - Creators Morgis</title>
            </Helmet>
            <div className='mt-[50px]'>
                <Home slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
                <Create />
                <Host />
                <AdvantagesList />
                <SliderBg sliderBg={sliderBg} quoteTitle={languageKeys?.entrepreneursQuoteDesc} quoteName={languageKeys?.entrepreneursQuoteName} quoterole={languageKeys?.entrepreneursQuoteRole} />
                <Ingredient />
                <Faq />
            </div>
        </div>
    )
}

export default Entrepreneurs
