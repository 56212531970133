import React, { useEffect, useState } from 'react'
import { slide as Menu } from 'react-burger-menu';
import arrow from '../Images/menuArrow.svg';
import { useLokaliser } from 'lokaliser'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { cn } from '../utills/utills';

function BurgerMenu({ menuOpen, setMenuOpen }) {

  const { language } = useLokaliser()
  const [selectedPage, setSelectedPage] = useState('')

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };
  const { languageKeys } = useLokaliser()


  const location = useLocation();

  useEffect(() => {
    setMenuOpen(false)
    let pathname = location.pathname.split('/')[2] || ''

    setSelectedPage(pathname)
  }, [location]);

  return (
    <div>
      <Menu
        isOpen={menuOpen}
        onStateChange={handleStateChange}
        pageWrapId={'page-wrap'}
        outerContainerId={'outer-container'}
        left
      >
        <div className='p-4 relative z-5'>
          <div className='flex justify-between items-center'>
            <p className='text-[20px] text-[#8464DD] font-bold uppercase montserrat'>{languageKeys?.menuTitle}</p>

            <svg onClick={e => setMenuOpen(false)} className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M16 2L2 16M2 2L16 16" stroke="#8464DD" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div className='mt-4 gap-3 grid'>
            <Link
              to={`/${language}`}
              className={cn("text-[24px] montserrat text-white font-semibold flex items-center cursor-pointer", {
                'text-[#8464DD]': selectedPage === ''
              })}
            >
              {languageKeys?.mainMenuLinkHome}
              <img className='w-[18px] ml-2' src={arrow} alt="arrow" />
            </Link>

            <Link
              to={`/${language}/athlete`}
              className={cn("text-[24px] montserrat text-white font-semibold flex items-center cursor-pointer", {
                'text-[#8464DD]': selectedPage === 'athlete'
              })}
            >
              {languageKeys?.mainMenuLink1}
              <img className='w-[18px] ml-2' src={arrow} alt="arrow" />
            </Link>

            <Link
              to={`/${language}/artists`}
              className={cn("text-[24px] montserrat text-white font-semibold flex items-center cursor-pointer", {
                'text-[#8464DD]': selectedPage === 'artists'
              })}
            >
              {languageKeys?.mainMenuLink2}
              <img className='w-[18px] ml-2' src={arrow} alt="arrow" />
            </Link>

            <Link
              to={`/${language}/teachers`}
              className={cn("text-[24px] montserrat text-white font-semibold flex items-center cursor-pointer", {
                'text-[#8464DD]': selectedPage === 'teachers'
              })}
            >
              {languageKeys?.mainMenuLink3}
              <img className='w-[18px] ml-2' src={arrow} alt="arrow" />
            </Link>

            <Link
              to={`/${language}/models`}
              className={cn("text-[24px] montserrat text-white font-semibold flex items-center cursor-pointer", {
                'text-[#8464DD]': selectedPage === 'models'
              })}
            >
              {languageKeys?.mainMenuLink4}
              <img className='w-[18px] ml-2' src={arrow} alt="arrow" />
            </Link>

            <Link
              to={`/${language}/entrepreneurs`}
              className={cn("text-[24px] montserrat text-white font-semibold flex items-center cursor-pointer", {
                'text-[#8464DD]': selectedPage === 'entrepreneurs'
              })}
            >
              {languageKeys?.mainMenuLink5}
              <img className='w-[18px] ml-2' src={arrow} alt="arrow" />
            </Link>

            <Link
              to={`/${language}/influencer`}
              className={cn("text-[24px] montserrat text-white font-semibold flex items-center cursor-pointer", {
                'text-[#8464DD]': selectedPage === 'influencer'
              })}
            >
              {languageKeys?.mainMenuLink6}
              <img className='w-[18px] ml-2' src={arrow} alt="arrow" />
            </Link>

            <Link
              to={`/${language}/licensed`}
              className={cn("text-[24px] montserrat text-white font-semibold flex items-center cursor-pointer", {
                'text-[#8464DD]': selectedPage === 'licensed'
              })}
            >
              {languageKeys?.mainMenuLink7}
              <img className='w-[18px] ml-2' src={arrow} alt="arrow" />
            </Link>



            <Link to={'https://app.staging.morgis.com/auth/signup-host'} target='_blank' className="text-[24px] montserrat text-[#8464DD] underline font-semibold flex items-center cursor-pointer">
              {languageKeys?.mainMenuLink8}
            </Link>

            <Link to={'https://app.staging.morgis.com/auth/login-host'} target='_blank' className="text-[24px] montserrat text-[#8464DD] underline font-semibold flex items-center cursor-pointer">
              {languageKeys?.mainMenuLink9}
            </Link>


          </div>
        </div>
      </Menu>
    </div>
  )
}

export default BurgerMenu
