
import React, { useState } from 'react'
import Create from './Main/Create'
import Faq from '../Components/Faq'
import Home from './Main/Home'
import Host from './Main/Host'
import Ingredient from './Main/Ingredient'
import SliderBg from './Main/SliderBg'
import AdvantagesList from './Main/AdvantagesList'
import slide1 from '../Images/Paid/Teachers/slide1.gif';
import slide2 from '../Images/Paid/Teachers/slide2.gif';
import slide3 from '../Images/Paid/Teachers/slide3.gif';
import slide4 from '../Images/Paid/Teachers/slide4.gif';
import slideBg1 from '../Images/bg/Teachers/bg1.png';
import slideBg2 from '../Images/bg/Teachers/bg2.png';
import slideBg3 from '../Images/bg/Teachers/bg3.png';
import slideBg4 from '../Images/bg/Teachers/bg4.png';
import slideBgDesk1 from '../Images/bg/Teachers/bg1Desk.png';
import slideBgDesk2 from '../Images/bg/Teachers/bg2Desk.png';
import slideBgDesk3 from '../Images/bg/Teachers/bg3Desk.png';
import slideBgDesk4 from '../Images/bg/Teachers/bg4Desk.png';
import { useLokaliser } from 'lokaliser';
import sliderBg1 from '../Images/slideBg/teachers1.png';
import sliderBg1Desk from '../Images/slideBg/teachers1Desk.png';
import { Helmet } from 'react-helmet'

function Teachers() {
    const { languageKeys } = useLokaliser();
    const [activeSlide, setActiveSlide] = useState(0);

    const slides = [
        { id: 0, img: slide1, title: languageKeys?.teachersCardName2, subtitle: languageKeys?.teachersCardRole2, workText: languageKeys?.teachersCardBold2, paidText: languageKeys?.teachersCardLight2, underSlideTitle: languageKeys?.teachersCardSpan2, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageKeys?.teachersCardName3, subtitle: languageKeys?.teachersCardRole3, workText: languageKeys?.teachersCardBold3, paidText: languageKeys?.teachersCardLight3, underSlideTitle: languageKeys?.teachersCardSpan3, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageKeys?.teachersCardName4, subtitle: languageKeys?.teachersCardRole4, workText: languageKeys?.teachersCardBold4, paidText: languageKeys?.teachersCardLight4, underSlideTitle: languageKeys?.teachersCardSpan4, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageKeys?.teachersCardName1, subtitle: languageKeys?.teachersCardRole1, workText: languageKeys?.teachersCardBold1, paidText: languageKeys?.teachersCardLight1, underSlideTitle: languageKeys?.teachersCardSpan1, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
        { id: 0, img: slide1, title: languageKeys?.teachersCardName2, subtitle: languageKeys?.teachersCardRole2, workText: languageKeys?.teachersCardBold2, paidText: languageKeys?.teachersCardLight2, underSlideTitle: languageKeys?.teachersCardSpan2, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageKeys?.teachersCardName3, subtitle: languageKeys?.teachersCardRole3, workText: languageKeys?.teachersCardBold3, paidText: languageKeys?.teachersCardLight3, underSlideTitle: languageKeys?.teachersCardSpan3, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageKeys?.teachersCardName4, subtitle: languageKeys?.teachersCardRole4, workText: languageKeys?.teachersCardBold4, paidText: languageKeys?.teachersCardLight4, underSlideTitle: languageKeys?.teachersCardSpan4, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageKeys?.teachersCardName1, subtitle: languageKeys?.teachersCardRole1, workText: languageKeys?.teachersCardBold1, paidText: languageKeys?.teachersCardLight1, underSlideTitle: languageKeys?.teachersCardSpan1, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
    ];

    const sliderBg = [
        {
            imageUrl: sliderBg1,
            imageUrlDesk: sliderBg1Desk
        }
    ];
    return (
        <div>
            <Helmet>
                <title>Teachers - Creators Morgis</title>
            </Helmet>
            <div className='mt-[50px]'>
                <Home slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
                <Create />
                <Host />
                <AdvantagesList />
                <SliderBg sliderBg={sliderBg} quoteTitle={languageKeys?.teachersQuoteDesc} quoteName={languageKeys?.teachersQuoteName} quoterole={languageKeys?.teachersQuoteRole} />
                <Ingredient />
                <Faq />
            </div>
        </div>
    )
}

export default Teachers
