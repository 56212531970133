import React, { useEffect } from 'react'

export default function HostRedirect() {
    useEffect(() => {

        const url = window.location.href;
        const slug = url.split('/')[4] || '';

        window.location.href = `/${slug}`;

    }, [])

    return (
        <div>HostRedirect</div>
    )
}
