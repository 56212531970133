import React, { useState, useEffect, useRef } from 'react';
import { useLokaliser } from 'lokaliser';
import { Link } from 'react-router-dom';
import { cn } from '../../utills/utills';
import createGis from '../../Images/createGif.gif';

function Create() {
    const { languageKeys } = useLokaliser();
    const [slideStep, setSlideStep] = useState(0);
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        const observerOptions = {
            root: null, // Using the viewport as the root
            rootMargin: '0px',
            threshold: [1, 0.5, 1] // Array of thresholds to trigger callback
        };

        const handleIntersect = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Calculate the progress based on the intersection ratio
                    const progress = Math.round(entry.intersectionRatio * 4);
                    if (progress !== slideStep) {
                        setSlideStep(3);
                    }
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersect, observerOptions);

        if (container) {
            observer.observe(container);
        }

        return () => {
            if (container) {
                observer.unobserve(container);
            }
        };
    }, [slideStep]);

    return (
        <div ref={containerRef} className="createGradient h-[800px] md:h-[1000px] bg-cover">

            <div className={cn("bg-createBg2 md:bg-createBg2Desk bg-cover min-h-[420px] duration-300 bg-center")}>
                <div
                    className={cn(
                        'h-full w-full bg-black/60 duration-500 opacity-0',
                        { 'opacity-100': slideStep !== 0 }
                    )}
                ></div>
            </div>
            {/*
            <p className="text-center text-[#FEB80C] text-[58px] font-medium uppercase tracking-[10px] leading-[60px] md:leading-[50px] mt-[-85px] md:mt-[-55px] absolute left-1/2 transform -translate-x-1/2">
                <span
                    className={cn(
                        'top-[200px] relative opacity-0 duration-500',
                        { 'opacity-100 top-0': slideStep >= 1 },
                        { 'opacity-100 top-[-20px]': slideStep === 2 },
                        { 'opacity-100 top-[-40px]': slideStep === 3 }
                    )}
                >
                    {languageKeys?.mainCreateTitle1}
                    <br />
                </span>
                <span
                    className={cn(
                        'top-[200px] relative opacity-0 duration-500',
                        { 'opacity-100 top-0': slideStep >= 2 },
                        { 'opacity-100 top-[-20px]': slideStep === 3 }
                    )}
                >
                    {languageKeys?.mainCreateTitle2}
                    <br />
                </span>
                <span
                    className={cn(
                        'top-[200px] relative opacity-0 duration-500',
                        { 'opacity-100 top-0': slideStep >= 3 }
                    )}
                >
                    {languageKeys?.mainCreateTitle3}
                    <br />
                </span>
            </p>
*/}
            <img className=' max-w-[430px] w-full mt-[-215px] absolute left-1/2 transform -translate-x-1/2' src={createGis} alt="createGis" />
            <div className="pt-32 md:pt-40 pb-6 p-3">
                <div
                    className={cn('opacity-0 duration-500 text-center', {
                        'opacity-100': slideStep === 3,
                    })}
                >
                    <p className="text-xl md:text-2xl font-light leading-8 md:max-w-[540px] text-black md:m-auto text-left md:pb-6 pt-10 md:pt-24">
                        {languageKeys?.mainCreateSubTitle}
                    </p>
                    <Link
                        className="w-full"
                        to="https://app.staging.morgis.com/auth/signup-host"
                        target="_blank"
                    >
                        <button className="bg-black m-auto flex items-center justify-center rounded-[21px] my-2 mt-8 py-2 px-4 tracking-[1px]">
                            <p className="text-white text-sm font-medium oswald">
                                {languageKeys?.createNewBtn}
                            </p>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Create;