import React, {useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import slide1 from '../../Images/slideBg1.png';
import { useLokaliser } from 'lokaliser';
import { Link } from 'react-router-dom';
import sliderBg1 from '../../Images/slideBg1.png';
import sliderBg1Desk from '../../Images/slideBg1Desk.png';
import sliderBg2 from '../../Images/slideBg2.png';
import sliderBg2Desk from '../../Images/slideBg2Desk.png';
import sliderBg3 from '../../Images/slideBg3.png';
import sliderBg3Desk from '../../Images/slideBg3Desk.png';
import sliderBg4 from '../../Images/slideBg4.png';
import sliderBg4Desk from '../../Images/slideBg4Desk.png';
import sliderBg5 from '../../Images/slideBg5.png';
import sliderBg5Desk from '../../Images/slideBg5Desk.png';
import sliderBg6 from '../../Images/slideBg6.png';
import sliderBg6Desk from '../../Images/slideBg6Desk.png';

function SliderBg({ sliderBg = [], quoteTitle, quoteName, quoterole }) {
    const { languageKeys } = useLokaliser();
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const sliderBgNew = [
        {
            imageUrl: sliderBg1,
            imageUrlDesk: sliderBg1Desk,
            title1: languageKeys?.mainQuote1,
            title2: languageKeys?.mainQuote2,
            name: languageKeys?.mainQuoteName,
            role: languageKeys?.mainQuoteRole,
        },
        {
            imageUrl: sliderBg2,
            imageUrlDesk: sliderBg2Desk,
            title2: languageKeys?.athleteQuoteDesc,
            name: languageKeys?.athleteQuoteName,
            role: languageKeys?.athleteQuoteRole,
        },
        {
            imageUrl: sliderBg3,
            imageUrlDesk: sliderBg3Desk,
            title2: languageKeys?.modelsQuoteDesc,
            name: languageKeys?.modelsQuoteName,
            role: languageKeys?.modelsQuoteRole,
        },
        {
            imageUrl: sliderBg4,
            imageUrlDesk: sliderBg4Desk,
            title2: languageKeys?.artistsQuoteDesc,
            name: languageKeys?.artistsQuoteName,
            role: languageKeys?.artistsQuoteRole,
        },
        {
            imageUrl: sliderBg5,
            imageUrlDesk: sliderBg5Desk,
            title2: languageKeys?.teachersQuoteDesc,
            name: languageKeys?.teachersQuoteName,
            role: languageKeys?.teachersQuoteRole,
        },
        {
            imageUrl: sliderBg6,
            imageUrlDesk: sliderBg6Desk,
            title2: languageKeys?.entrepreneursQuoteDesc,
            name: languageKeys?.entrepreneursQuoteName,
            role: languageKeys?.entrepreneursQuoteRole,
        },
    ];
    const handleSlideChange = (swiper) => {
        setActiveSlideIndex(swiper.realIndex);
    };
    return (
        <div className="w-full max-w-lg md:max-w-[unset] mx-auto relative">
        <Swiper
            className="mySwiper relative"
            modules={[Navigation, Autoplay, Pagination]}
            pagination={{
                clickable: true
            }}
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            onSlideChange={handleSlideChange}
        >
            {sliderBgNew.map((slide, index) => (
                <SwiperSlide key={index}>
                    <img
                        src={slide.imageUrl}
                        alt={`Slide ${index + 1}`}
                        className="w-full object-cover md:h-[578px] md:hidden"
                    />
                    <img
                        src={slide.imageUrlDesk}
                        alt={`Slide ${index + 1}`}
                        className="w-full object-cover md:h-[578px] hidden md:block"
                    />
                </SwiperSlide>
            ))}
        </Swiper>

        <div className="md:max-w-[500px] w-full md:mx-auto absolute z-10 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
            <div className="max-w-[375px] w-full md:m-auto px-2">
                <p className="text-white text-2xl text-right font-normal lato tracking-[2px]">
                    {sliderBgNew[activeSlideIndex]?.title1}
                </p>
                <p className="text-white text-2xl text-right font-light lato tracking-[2px]">
                    {sliderBgNew[activeSlideIndex]?.title2}
                </p>
                <p className="text-white text-lg font-semibold text-right mt-6 leading-6">
                    {sliderBgNew[activeSlideIndex]?.name}
                </p>
                <p className="text-white text-base font-light text-right">
                    {sliderBgNew[activeSlideIndex]?.role}
                </p>
            </div>
        </div>

        <Link
            className="w-full absolute z-10 bottom-4"
            to="https://app.staging.morgis.com/auth/signup-host"
            target="_blank"
        >
            <button className="bg-[#8649D1] w-full max-w-[250px] md:max-w-[450px] md:h-[70px] m-auto text-white text-lg h-[50px] flex items-center justify-center rounded-[21px] font-medium tracking-[1px]">
                {languageKeys?.mainHomeBtn}
            </button>
        </Link>
    </div>
    );
}

export default SliderBg;
