
import React, { useState } from 'react'
import Faq from '../Components/Faq'
import Create from './Main/Create'
import Home from './Main/Home'
import Host from './Main/Host'
import Ingredient from './Main/Ingredient'
import SliderBg from './Main/SliderBg'
import AdvantagesList from './Main/AdvantagesList'
import slide1 from '../Images/Paid/Models/slide1.gif';
import slide2 from '../Images/Paid/Models/slide2.gif';
import slide3 from '../Images/Paid/Models/slide3.gif';
import slide4 from '../Images/Paid/Models/slide4.gif';
import slideBg1 from '../Images/bg/Models/bg1.png';
import slideBg2 from '../Images/bg/Models/bg2.png';
import slideBg3 from '../Images/bg/Models/bg3.png';
import slideBg4 from '../Images/bg/Models/bg4.png';
import slideBgDesk1 from '../Images/bg/Models/bg1Desk.png';
import slideBgDesk2 from '../Images/bg/Models/bg2Desk.png';
import slideBgDesk3 from '../Images/bg/Models/bg3Desk.png';
import slideBgDesk4 from '../Images/bg/Models/bg4Desk.png';
import { useLokaliser } from 'lokaliser';
import sliderBg1 from '../Images/slideBg/models1.png';
import sliderBg1Desk from '../Images/slideBg/models1Desk.png';
import { Helmet } from 'react-helmet'

function Models() {
    const { languageKeys } = useLokaliser();
    const [activeSlide, setActiveSlide] = useState(0);

    const slides = [
        { id: 0, img: slide1, title: languageKeys?.modelsCardName2, subtitle: languageKeys?.modelsCardRole2, workText: languageKeys?.modelsCardBold2, paidText: languageKeys?.modelsCardLight2, underSlideTitle: languageKeys?.modelsCardSpan2, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageKeys?.modelsCardName3, subtitle: languageKeys?.modelsCardRole3, workText: languageKeys?.modelsCardBold3, paidText: languageKeys?.modelsCardLight3, underSlideTitle: languageKeys?.modelsCardSpan3, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageKeys?.modelsCardName4, subtitle: languageKeys?.modelsCardRole4, workText: languageKeys?.modelsCardBold4, paidText: languageKeys?.modelsCardLight4, underSlideTitle: languageKeys?.modelsCardSpan4, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageKeys?.modelsCardName1, subtitle: languageKeys?.modelsCardRole1, workText: languageKeys?.modelsCardBold1, paidText: languageKeys?.modelsCardLight1, underSlideTitle: languageKeys?.modelsCardSpan1, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
        { id: 0, img: slide1, title: languageKeys?.modelsCardName2, subtitle: languageKeys?.modelsCardRole2, workText: languageKeys?.modelsCardBold2, paidText: languageKeys?.modelsCardLight2, underSlideTitle: languageKeys?.modelsCardSpan2, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageKeys?.modelsCardName3, subtitle: languageKeys?.modelsCardRole3, workText: languageKeys?.modelsCardBold3, paidText: languageKeys?.modelsCardLight3, underSlideTitle: languageKeys?.modelsCardSpan3, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageKeys?.modelsCardName4, subtitle: languageKeys?.modelsCardRole4, workText: languageKeys?.modelsCardBold4, paidText: languageKeys?.modelsCardLight4, underSlideTitle: languageKeys?.modelsCardSpan4, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageKeys?.modelsCardName1, subtitle: languageKeys?.modelsCardRole1, workText: languageKeys?.modelsCardBold1, paidText: languageKeys?.modelsCardLight1, underSlideTitle: languageKeys?.modelsCardSpan1, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
    ];

    const sliderBg = [
        {
            imageUrl: sliderBg1,
            imageUrlDesk: sliderBg1Desk
        }
    ];
    return (
        <div>
            <Helmet>
                <title>Models - Creators Morgis</title>
            </Helmet>
            <div className='mt-[50px]'>
                <Home slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
                <Create />
                <Host />
                <AdvantagesList />
                <SliderBg sliderBg={sliderBg} quoteTitle={languageKeys?.modelsQuoteDesc} quoteName={languageKeys?.modelsQuoteName} quoterole={languageKeys?.modelsQuoteRole} />
                <Ingredient />
                <Faq />
            </div>
        </div>
    )
}

export default Models
