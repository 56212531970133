// lib/utils.ts
import axios from "axios";
import clsx, { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export async function selectLanguage() {
    const res = await axios.get('https://geosvc.globalmailer.com/mygeoipinfo?json=1&corsdef=1')
    const country = res.data.Data.country

    if (country === 'BR') {
        return 'pt'
    } else {
        return 'en'
    }

}