import React, { useEffect, useState } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import gemIcon from '../../Images/hostPage/gemIcon.svg';
import { useLokaliser } from 'lokaliser';
import { Link } from 'react-router-dom';

const tierData = {
  en: [
    {
      title: 'st Tier',
      features: ['2 Surprises', '1 Broadcast group'],
    },
    {
      title: 'nd Tier',
      features: ['5 Surprises', '2 Broadcast groups'],
    },
    {
      title: 'rd Tier',
      features: ['10 Surprises', '3 Broadcast groups'],
    },
    {
      title: 'rd Tier',
      features: ['15 Surprises', '4 Broadcast groups'],
    },

  ],
  es: [
    {
      title: 'st Tier',
      features: ['2 Surprises', '1 Broadcast group'],
    },
    {
      title: 'nd Tier',
      features: ['5 Surprises', '2 Broadcast groups'],
    },
    {
      title: 'rd Tier',
      features: ['10 Surprises', '3 Broadcast groups'],
    },
    {
      title: 'rd Tier',
      features: ['15 Surprises', '4 Broadcast groups'],
    },

  ],
  pt: [
    {
      title: 'st Tier',
      features: ['2 Surprises', '1 Broadcast group'],
    },
    {
      title: 'nd Tier',
      features: ['5 Surprises', '2 Broadcast groups'],
    },
    {
      title: 'rd Tier',
      features: ['10 Surprises', '3 Broadcast groups'],
    },
    {
      title: 'rd Tier',
      features: ['15 Surprises', '4 Broadcast groups'],
    },

  ],
}

function TierSlider({ data }) {
  const [activeIndex, setActiveIndex] = useState(0);


 useEffect(() => {
    console.log('Tier Slider', data);
 }, [data]);
  const { language } = useLokaliser();

  return (
    <div className='max-w-[1350px] w-full m-auto md:px-2'>
      <Swiper
        spaceBetween={10}
        loop={true}
        onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
        breakpoints={{
          0: {
            slidesPerView: 2,
            centeredSlides: true,
          },
          768: {
            slidesPerView: 4,
            centeredSlides: false,
          },
        }}
        className=" mb-12 h-auto"
      >
        {data?.map((plan, index) => (
          <SwiperSlide key={index} className="select-none cursor-pointer">
            <div
              className={`w-full h-[275px] lg:h-[unset] lg:max-sw-[290px] bg-white rounded-[16px] lg:rounded-[25px] p-3 lg:p-4 swiper-slide-content duration-150 ${activeIndex === index ? 'border-2 border-[#7200FF] md:border-none' : 'mt-4 md:mt-0'
                }`}
            >
              <div className='text-center text-base lg:text-2xl text-white inter font-bold bg-[#A65EFF] py-3 rounded-[10px]'>
                {
                  Number(plan?.tier) + tierData[language][Number(plan?.tier) - 1]?.title
                }
              </div>
              <hr className='w-full h-[1px] bg-[#EAEAEA] my-3 lg:my-4' />
              {
                tierData[language][Number(plan?.tier) - 1]?.features.map((feature, i) => (
                  <div key={i} className='flex items-center mt-1'>
                    <img className='w-[16px] lg:w-[25px]' src={gemIcon} alt="gemIcon" />
                    <p className='text-xs lg:text-lg inter text-[#00063D] ml-1 lg:ml-2'>{feature}</p>
                  </div>
                ))
              }
              <img className='w-full h-[70px] lg:h-[100px] object-cover rounded-md mt-3' src={plan.image} alt={plan.title} />
              <a href={plan.cta_link} target='_blank'>
                <button className='border-2 border-[#8649D1] text-[#8649D1] font-medium text-base lg:text-2xl w-full text-center rounded-xl py-2 mt-4 hover:bg-[#8649D1] hover:text-white hover:border-white duration-300'>
                  {plan.cta_text}
                </button>
              </a>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default TierSlider;
