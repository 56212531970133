import React, { useState, useRef, useEffect } from 'react';
import arrow from '../Images/faqArrow.svg';
import { useLokaliser } from 'lokaliser'
import axios from 'axios'

function FaqItem({ question, answer }) {

    const [isOpen, setIsOpen] = useState(false);
    const [maxHeight, setMaxHeight] = useState('0px');
    const contentRef = useRef(null);

    const toggleFaq = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        setMaxHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
    }, [isOpen]);

    const [country, setCountry] = useState('')

    useEffect(() => {
        getCountry()
    }, [])

    async function getCountry() {
        const res = await axios.get('https://geosvc.globalmailer.com/mygeoipinfo?json=1&corsdef=1')
        setCountry(res.data.Data.country)
    }

    return (
        <div className='mb-4 border-b-[0.5px] border-[#E9A500] pb-2 md:pb-4'>
            <div className='flex justify-between items-center cursor-pointer' onClick={toggleFaq}>
                <p className='text-sm md:text-xl font-semibold  text-[#353535] max-w-[280px] md:max-w-[unset]'>{question}</p>
                <img
                    className={`w-6 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}
                    src={arrow}
                    alt="arrow"
                />
            </div>
            <div
                ref={contentRef}
                style={{ maxHeight }}
                className={`overflow-hidden transition-all duration-500 ease-in-out text-sm md:text-xl font-normal  text-[#353535]`}
            >
                {
                    processAnswer(answer, country)
                }
            </div>
        </div>
    );
}

function Faq() {
    const { languageKeys } = useLokaliser()
    console.log(languageKeys)
    return (
        <div className='bg-[#FEB80C] p-4 md:py-8'>
            <div className='max-w-[1110px] m-auto'>
                <p className='text-[#151515] font-light text-4xl tracking-[1.8px] max-w-[300px] mb-8'>
                    {languageKeys?.FAQTitle}
                </p>

                {

                    languageKeys?.faq.map((item, index) => (
                        <FaqItem key={index} question={item.question} answer={item.answer} />
                    ))

                }
            </div>
        </div>
    );
}

export default Faq;


const processAnswer = (answer, country) => {

    answer = answer.replace('{countryPayment}', country === 'BR' ? 'PIX' : 'PayPal');

    if (answer.indexOf('<br>') !== -1) {
        let paragraphs = answer.split('<br>');

        return paragraphs.map((p, index) => (
            <p key={index} className='mt-2 text-sm md:text-xl font-normal text-[#353535]'>{p}</p>
        ));
    } else {
        return answer;
    }


}