import "./App.css";
import { LokaliserProvider } from "lokaliser";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RoutesList from "./Pages/RoutesList";
import morgis_lokaliser from "./morgis-lokaliser.json";
import NotFound from "./Pages/NotFound";

function App() {
  const params = new URLSearchParams(window.location.search);
  const variant = params.get("p");

  return (
    <>
      <div className="App">
        <LokaliserProvider
          projectID={"047a7bf9-2b63-4896-8dfa-b570e32d0a69"}
          apiKey={"6b345684-5477-4452-b568-8f6fd0fe7254"}
          variant={variant || "default"}
          localJSON={morgis_lokaliser}
          countryDetect={true}
        >
          <BrowserRouter>
            <Routes>
              
              <Route path="/*" element={<RoutesList />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </LokaliserProvider>
      </div>
    </>
  );
}

export default App;
