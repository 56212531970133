
import React, { useState } from 'react'
import Faq from '../Components/Faq'
import Create from './Main/Create'
import Home from './Main/Home'
import Host from './Main/Host'
import Ingredient from './Main/Ingredient'
import SliderBg from './Main/SliderBg'
import AdvantagesList from './Main/AdvantagesList'
import slide1 from '../Images/Paid/Sleazy/slide1.gif';
import slide2 from '../Images/Paid/Sleazy/slide2.gif';
import slide3 from '../Images/Paid/Sleazy/slide3.gif';
import slide4 from '../Images/Paid/Sleazy/slide4.gif';
import slideBg1 from '../Images/bg/Sleazy/bg1.png';
import slideBg2 from '../Images/bg/Sleazy/bg3.png';
import slideBg3 from '../Images/bg/Sleazy/bg2.png';
import slideBg4 from '../Images/bg/Sleazy/bg4.png';
import slideBgDesk1 from '../Images/bg/Sleazy/bg4Desk.png';
import slideBgDesk2 from '../Images/bg/Sleazy/bg2Desk.png';
import slideBgDesk3 from '../Images/bg/Sleazy/bg3Desk.png';
import slideBgDesk4 from '../Images/bg/Sleazy/bg1Desk.png';
import { useLokaliser } from 'lokaliser';
import sliderBg1 from '../Images/slideBg/sleazy1.png';
import sliderBg1Desk from '../Images/slideBg/sleazy1Desk.png';
import { Helmet } from 'react-helmet'
import emogi from '../Images/emogi.png';

function Sleazy() {
    const { languageKeys } = useLokaliser();
    const [activeSlide, setActiveSlide] = useState(0);

    const slides = [
        { id: 0, img: slide1, title: languageKeys?.sleazyCardName1, subtitle: languageKeys?.sleazyCardRole1, workText: languageKeys?.sleazyCardBold1, paidText: languageKeys?.sleazyCardLight1, underSlideTitle: languageKeys?.sleazyCardSpan1, imageBg: slideBg1, imageBgDesk: slideBgDesk1, emogi: emogi },
        { id: 1, img: slide2, title: languageKeys?.sleazyCardName2, subtitle: languageKeys?.sleazyCardRole2, workText: languageKeys?.sleazyCardBold2, paidText: languageKeys?.sleazyCardLight2, underSlideTitle: languageKeys?.sleazyCardSpan2, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageKeys?.sleazyCardName3, subtitle: languageKeys?.sleazyCardRole3, workText: languageKeys?.sleazyCardBold3, paidText: languageKeys?.sleazyCardLight3, underSlideTitle: languageKeys?.sleazyCardSpan3, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageKeys?.sleazyCardName4, subtitle: languageKeys?.sleazyCardRole4, workText: languageKeys?.sleazyCardBold4, paidText: languageKeys?.sleazyCardLight4, underSlideTitle: languageKeys?.sleazyCardSpan4, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
        { id: 0, img: slide1, title: languageKeys?.sleazyCardName1, subtitle: languageKeys?.sleazyCardRole1, workText: languageKeys?.sleazyCardBold1, paidText: languageKeys?.sleazyCardLight1, underSlideTitle: languageKeys?.sleazyCardSpan1, imageBg: slideBg1, imageBgDesk: slideBgDesk1, emogi: emogi },
        { id: 1, img: slide2, title: languageKeys?.sleazyCardName2, subtitle: languageKeys?.sleazyCardRole2, workText: languageKeys?.sleazyCardBold2, paidText: languageKeys?.sleazyCardLight2, underSlideTitle: languageKeys?.sleazyCardSpan2, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageKeys?.sleazyCardName3, subtitle: languageKeys?.sleazyCardRole3, workText: languageKeys?.sleazyCardBold3, paidText: languageKeys?.sleazyCardLight3, underSlideTitle: languageKeys?.sleazyCardSpan3, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageKeys?.sleazyCardName4, subtitle: languageKeys?.sleazyCardRole4, workText: languageKeys?.sleazyCardBold4, paidText: languageKeys?.sleazyCardLight4, underSlideTitle: languageKeys?.sleazyCardSpan4, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
    ];


    
    const sliderBg = [
        {
            imageUrl: sliderBg1,
            imageUrlDesk: sliderBg1Desk
        }
    ];
    return (
        <div>
            <Helmet>
                <title>Sleazy - Creators Morgis</title>
            </Helmet>
            <div className='mt-[50px]'>
                <Home slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
                <Create />
                <Host />
                <AdvantagesList />
                <SliderBg sliderBg={sliderBg} quoteTitle={languageKeys?.sleazyQuoteDesc} quoteName={languageKeys?.sleazyQuoteName} quoterole={languageKeys?.sleazyQuoteRole} />
                <Ingredient />
                <Faq />
            </div>
        </div>
    )
}

export default Sleazy
