import React, { useRef, useEffect } from 'react';
import 'swiper/css';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLokaliser } from 'lokaliser';
import { Link } from 'react-router-dom';
import FlipText from '../../Components/FlipText';

const preloadImage = (url) => {
    const img = new Image();
    img.src = url;
};

function Home({ slides, activeSlide, setActiveSlide }) {

    const { languageKeys } = useLokaliser();
    const swiperRef = useRef(null);

    const handleSlideChange = (swiper) => {
        const realIndex = swiper.realIndex;
        console.log('Current slide index:', realIndex);
        setActiveSlide(realIndex);
    };

    useEffect(() => {
        slides.forEach((slide) => preloadImage(slide.img));
    }, [slides]);


    return (
        <div>
            <div className={`background-transition h-[94vh] md:h-[calc(100vh-50px)] bg-no-repeat bg-cover bg-center flex items-end bg-[#8649D1]`}>
                <img className='w-full absolute z-0 h-[94vh] md:h-[calc(100vh-50px)] bg-no-repeat object-cover hidden md:block' src={slides[activeSlide].imageBgDesk} alt="imgBg" />
                <img className='w-full absolute z-0 h-[94vh] md:h-[calc(100vh-50px)] bg-no-repeat object-cover md:hidden' src={slides[activeSlide].imageBg} alt="imgBg" />
                <div className='p-4 w-full max-w-[750px] mx-auto mb-[70px] md:mb-0 relative'>
                    <p className='text-4xl md:text-5xl text-white font-light tracking-[3.6px] md:tracking-[6px] md:leading-[57px]'>{languageKeys?.mainTitle1} <br /> {languageKeys?.mainTitle2} <br /> {languageKeys?.mainTitle3} <br /> {languageKeys?.mainTitle4}</p>
                    <div className='h-[130px]'>
                        {slides[activeSlide].emogi && (
                            <img className='w-[60px] md:w-[70px] pt-4' src={slides[activeSlide].emogi} alt="emogi" />
                        )}
                        <FlipText
                            className={`oswald text-[#8649D1] ${(slides[activeSlide].workText || '').length > 10 ? 'text-[32px] md:text-[60px]' : 'text-[56px] md:text-[78px]'
                                } font-bold md:tracking-[10px] uppercase py-2 md:py-4`}
                            word={slides[activeSlide].workText || ''}
                        />
                    </div>

                    <div className='flex justify-center md:justify-start mb-4'>
                        <Link className='w-full' to='https://app.staging.morgis.com/auth/signup-host' target='_blank'>
                            <button className='bg-[#8649D1] w-full max-w-[250px] md:max-w-[450px] m-auto md:ml-0 text-white text-lg md:text-2xl h-[50px] md:h-[70px] flex items-center justify-center rounded-[21px] md:rounded-[30px] font-medium my-3'>{languageKeys?.mainHomeBtn}</button>
                        </Link>
                    </div>
                </div>
            </div >
            <div className='homeGradientBg pb-4 px-4 md:px-[50px] 2xl:px-[200px] md:py-6'>
                <div className='max-w-[850px] m-auto'>
                    <p className='text-[40px] md:text-[72px] font-bold text-white pt-4 relative z-30 max-w-[350px] md:max-w-[unset] leading-10 md:text-center'>{slides[activeSlide].paidText}</p>
                </div>
                <div className='relative z-10 md:max-w-[unset] md:m-auto '>
                    <p className='text-[38px] md:text-[72px] text-white font-light md:text-center'>{slides[activeSlide].underSlideTitle}</p>
                </div>
                <div className='md:max-w-[1250px] md:w-full m-auto'>
                    <Swiper
                        ref={swiperRef}
                        spaceBetween={30}
                        loop={true}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                centeredSlides: true,
                            },
                            768: {
                                slidesPerView: 2.9,
                                centeredSlides: false,
                            },
                        }}
                        modules={[Navigation, Autoplay]}
                        onSlideChange={handleSlideChange}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        className="my-4 md:!px-[52px] md:!py-20 md:!pl-[330px]"
                    >
                        {slides.map((slide, index) => (
                            <SwiperSlide
                                className={`md:!max-w-[300px] transition-transform select-none cursor-pointer`}
                                key={slide.id}
                                style={{ zIndex: index === activeSlide ? 10 : 1 }}
                            >
                                <div
                                    className={`md:max-w-[300px] relative duration-300 rounded-2xl ${index === activeSlide ? 'md:scale-[1.4]' : 'dimmed-slide'}`}
                                >
                                    <img
                                        className="w-full"
                                        src={slide.img}
                                        alt={`slide${slide.id}`}
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                </div>

                <div className='flex justify-center'>
                    <Link className='w-full' to='https://app.staging.morgis.com/auth/signup-host' target='_blank'>
                        <button className='bg-white w-full max-w-[250px] md:max-w-[450px] m-auto h-[50px] md:h-[70px] flex items-center justify-center rounded-[21px] my-1 md:my-3 '>
                            <p className='text-[#8649D1] text-lg md:text-2xl font-medium '>{languageKeys?.mainHomeBtn}</p>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Home;
